import { Space } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import ActionButton from '../../../common/components/buttons/ActionButton';
import { LrvTooltip } from '../../../common/components/LrvTooltip/LrvTooltip';
import { DetailHarvest } from '../MiniCharts/DetailHarvest';
import * as optimalHarvestPointSlice from '../optimalHarvestPointSlice';

import { Indicators } from './Indicators';
import styles from './IndicatorsPanel.module.scss';

export const IndicatorsPanel = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const theme = getCurrentTheme();

  return (
    <>
      <Space className={styles.container}>
        <Indicators />

        <Space className={styles.options}>
          <LrvTooltip
            themeStyle={theme}
            title={t('optimalHarvestPoint.detailHarvest')}
          >
            <div
              className={styles.detailHarvest}
              onClick={() => {
                dispatch(optimalHarvestPointSlice.setShowDetailHarvestModal(true));
              }}
            >
              <Icon name='bar-chart-2' theme={theme} className={styles.icon} />
            </div>
          </LrvTooltip>

          <ActionButton
            type='primary'
          >
            {t('optimalHarvestPoint.addPlan')}
          &nbsp;
            <Icon name='corner-up-right' theme={theme} className={styles.icon} />
          </ActionButton>
        </Space>
      </Space>
      
      <DetailHarvest />
    </>
  );
};
