import { Space } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AnimatedValue } from '../AnimatedValue';
import { calculatePresentValue } from '../helpers';
import { weightUnits } from '../../../config/commons';
import { Store } from '../../../state/store.interfaces';
import { formatLongDateWithZone } from '../../../utils/date';

import styles from './Indicators.module.scss';

export const Indicators = () => {
  const [t] = useTranslation();

  const {
    predictionSelected, allPredictions, pocPoint,
  } = useSelector((state: Store) => state.optimalHarvestPoint);

  const point = predictionSelected || pocPoint;
  const prediction = allPredictions.find((prediction) => prediction.x === point?.x);

  const renderPounds = (props: {label: string; value?: number; unit: string}) => {
    const { label, value = 0, unit } = props;

    return (
      <div>
        <div className={styles.value}>
          <AnimatedValue value={value} /> &nbsp;
          <div>{unit}</div>
        </div>

        <div className={styles.label}>{label}</div>
      </div>
    );
  };

  const renderUtility = (props: {label: string; value?: number; unit: string}) => {
    const { label, value = 0, unit } = props;

    return (
      <div>
        <div className={styles.value}>
          <div>{unit}</div>&nbsp;
          <AnimatedValue value={value} />
        </div>
        
        <div className={styles.label}>{label}</div>
      </div>
    );
  };

  const renderDate = (props: {label: string; value?: string;}) => {
    const { label, value = '' } = props;


    return (
      <div>
        <div className={styles.value}>
          <div>{formatLongDateWithZone({ date: value })}</div>
        </div>
        
        <div className={styles.label}>{label}</div>
      </div>
    );
  };

  return (
    <Space className={styles.container} size={24}>
      {renderPounds({ label: t('optimalHarvestPoint.poundsToHarvest'), value: prediction?.biomassLb, unit: weightUnits.LB })}
      {renderUtility({ label: t('optimalHarvestPoint.presentValueProfit'), value: calculatePresentValue({ futureValue: 1000, annualRate: 0.1043, days: 30 }), unit: '$' })}
      {renderUtility({ label: t('optimalHarvestPoint.profit'), value: point?.potentialGain, unit: '$' })}
      {renderDate({ label: t('optimalHarvestPoint.harvestDay'), value: prediction?.predictionDate })}
    </Space>
  );
};
