import { Button } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getLabelsAxisY } from '../helpers';
import Icon from '../../../common/components/Icon/Icon';
import { Store } from '../../../state/store.interfaces';
import { DEFAULT_CURRENCY_CODE } from '../../../config/commons';
import { getLabelAxisX } from '../../../helpers/stocking.helpers';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { useCurrency } from '../../../hooks/currency-codes/useCurrency';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import * as optimalHarvestPointSlice from '../optimalHarvestPointSlice';
import { ForecastMetricD3 } from '../../../common/components/charts/OptimalHarvestPoint/ForecastMetricD3';

import styles from './Chart.module.scss';

let chart: ForecastMetricD3 | null = null;

interface Props {
  theme?: 'dark' | 'light';
}

export const Chart = (props: Props) => {
  const { theme = 'light' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const currencyCode = localStorage.getItem('currencyCode') || DEFAULT_CURRENCY_CODE;
  
  const { findCurrencyByCode } = useCurrency();
  const currencySymbol = findCurrencyByCode(currencyCode)?.symbol || '';

  const refChart = useRef<HTMLDivElement>(null);

  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);
  const {
    firstStage,
    lastStage,
    allPredictions,
    predictionSelected,
    pocPoint,
    showMiniChartModal,
    filters,
    bestPackers,
  } = useSelector((state: Store) => state.optimalHarvestPoint);

  const { miniChartParameter } = filters;
  const point = predictionSelected || pocPoint;

  const title = getLabelsAxisY({ chartParameter: miniChartParameter, weightUnit: selectedCompany.weightUnit, currencySymbol });
  
  useEffect(() => {
    return () => {
      chart = null;
    };
  }, [dispatch]);

  useEffect(() => {
    if (allPredictions.length === 0 || !point?.packerId || !miniChartParameter || !showMiniChartModal || !refChart.current) {
      return;
    }

    if (chart) {
      chart.refreshChart({
        allPredictions,
        companyData: selectedCompany,
        chartParameter: miniChartParameter,
        firstStage,
        lastStage,
        packerId: point?.packerId,
        bestPackers,
      });
      return;
    }

    chart = new ForecastMetricD3({
      container: refChart.current,
      allPredictions,
      height: 400,
      width: 672,
      firstStage,
      lastStage,
      companyData: selectedCompany,
      chartParameter: miniChartParameter,
      packerId: point?.packerId,
      bestPackers,
      currencySymbol,
    });
  }, [allPredictions, firstStage, lastStage, selectedCompany, miniChartParameter, point, bestPackers, showMiniChartModal, refChart]);

  const onCancel = () => {
    dispatch(optimalHarvestPointSlice.setMiniChartParameter(undefined));
    dispatch(optimalHarvestPointSlice.setShowMiniChartModal(false));
  };

  return (
    <LrvModal
      theme={theme}
      title={title}
      open={showMiniChartModal}
      forceRender={true}
      destroyOnClose={true}
      closeIcon={<Icon id='close_modal' name='close' />}
      onCancel={onCancel}
      width={720}
      footer={[
        <Button
          type='default'
          className={styles.button}
          onClick={onCancel}
        >
          {t('priceTable.cancel').toUpperCase()}
        </Button>
      ]}
    >
      <div className={styles.container}>
        <div className={styles.labelAxisY}>
          <LrvText text={title} theme={theme}/>
        </div>

        <div
          ref={refChart}
          className={styles.chart}
        />

        <div className={styles.labelAxisX}>
          <LrvText text={getLabelAxisX(phaseType)} theme={theme}/>
        </div>
      </div>
    </LrvModal>
  );
};
