import * as d3 from 'd3';
import cx from 'classnames';
import i18next from 'i18next';

import { Company } from '../../../../pages/AppHeader/interfaces';
import { applyThousandsSeparator } from '../../../../utils/strings';
import { Dataset } from '../../../../pages/OptimalHarvestPoint/interfaces';
import { roundTwoDecimals, weightUnitsByCompany } from '../../../../config/commons';

import styles from './VerticalHistogramD3.module.scss';

const TIME_TRANSITION = 300;
const X_AXIS_PADDING = 0.05;
const EXTRA_HEIGHT_PRICES_AXIS = 28;

interface Props {
  container: HTMLDivElement | null;
  companyData: Company;
  dataset: Dataset[];
  width: number;
  height: number;
  currencySymbol: string;
  fillBars: string;
}

interface RefreshProps {
  companyData: Company;
  dataset: Dataset[];
  currencySymbol: string;
  fillBars: string;
}

export default class VerticalHistogramD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'svg'));

  xLabelScale: d3.ScaleBand<string> = d3.scaleBand();
  y: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  
  bars: d3.Selection<SVGRectElement, Dataset, null, undefined> = d3.select<SVGRectElement, Dataset>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  textFrequencies: d3.Selection<SVGTextElement, Dataset, null, unknown> = d3.select<SVGTextElement, Dataset>(document.createElementNS('http://www.w3.org/2000/svg', 'text'));;
  xAxisLabelScale: d3.Selection<SVGGElement, Dataset, null, undefined> = d3.select<SVGGElement, Dataset>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  xAxisPriceScale: d3.Selection<SVGTextElement, Dataset, null, unknown> = d3.select<SVGTextElement, Dataset>(document.createElementNS('http://www.w3.org/2000/svg', 'text'));;
  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));

  width = 0;
  height = 0;
  margin = { top: 25, right: 0, bottom: 30, left: 0 };

  companyData: Company;
  dataset: Dataset[];

  //styles
  fillBars: string;
  currencySymbol: string;

  constructor (props: Props) {
    const { container, dataset, height, width, companyData, currencySymbol, fillBars } = props;

    this.container = container;
    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    this.dataset = dataset;
    this.companyData = companyData;
    this.currencySymbol = currencySymbol;
    this.fillBars = fillBars;

    this.createSvgElement(this.width, this.height);
    this.createTooltip();
    this.createHistogram(dataset);
  }

  createTooltip = () => {
    const { container } = this;
    d3.select(container).selectAll('.tooltip').remove();

    this.tooltip = d3.select(container)
      .append('div')
      .attr('id', 'tooltipChart')
      .attr('class', cx('tooltip', styles.tooltip))
      .style('display', 'none')
      .on('mouseover', () => {
        this.tooltip.style('display', 'block');
      });
  }

  createSvgElement (width: number, height: number) {
    const { container, margin } = this;
    d3.select(container).select('svg').remove();
  
    this.svg = d3.select(container)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('id', 'content')
      .append('g')
      .attr('transform', `translate( ${margin.left} , ${margin.top} )`);
  }

  getFrecuencyPositionX (d: Dataset) {
    return (this.xLabelScale(d.label) as number) + (this.xLabelScale.bandwidth() / 2);
  }

  getFrecuencyPositionY = (d: Dataset) => {
    return this.y(d.frequency) - 5;
  }

  getFrecuencyLabel = (d: Dataset) => {
    const percent = roundTwoDecimals(d.percent);
    return `${percent} %`;
  }

  getBiomassKg = (d: Dataset) => {
    const biomass = Math.round(d.biomass);
    return applyThousandsSeparator(biomass);
  }

  getBiomassLb = (d: Dataset) => {
    const biomassLb = Math.round(d.biomassLb);
    return applyThousandsSeparator(biomassLb);
  }

  renderTooltip (d: Dataset) {
    if (d.frequency === 0) {
      return;
    }

    const { height, y, tooltip } = this;
    const bandWidth = this.xLabelScale.bandwidth();
    const maxTop = height / 1.2;

    let top = y(d.frequency);
    let left = Number(this.xLabelScale(d.label)) + bandWidth;

    const invalidTop = top > maxTop;

    tooltip
      .style('display', 'block');

    const tooltipWidth = tooltip.node()?.getBoundingClientRect()?.width || 0;
    const extraTooltipMargin = Math.abs(tooltipWidth - bandWidth);

    top = invalidTop ? (top - 95) : top;
    left = invalidTop ? ((left - bandWidth) - (extraTooltipMargin / 2)) : left;

    tooltip
      .classed(styles.leftAlignedTooltip, !invalidTop)
      .classed(styles.bottomAlignedTooltip, invalidTop)
      .style('top', `${top}px`)
      .style('left',`${left}px`);

    this.renderTooltipContent(d);
  }

  renderTooltipContent (d: Dataset) {
    const { tooltip, companyData, currencySymbol } = this;
    const { weightUnit } = companyData;
    tooltip.select('#tooltipContent').remove();

    const tooltipContent = tooltip.append('div')
      .attr('id', 'tooltipContent')
      .attr('class', styles.content);
  
    tooltipContent.append('div')
      .attr('class', styles.stat)
      .html(`<strong>${d.label}</strong>`);

    const biomassKg = this.getBiomassKg(d);
    const biomassLb = this.getBiomassLb(d);

    tooltipContent.append('div')
      .attr('class', styles.stat)
      .html(`${i18next.t('survivalRate.percent')}: <strong>${roundTwoDecimals(d.percent)} %</strong>`);

    tooltipContent.append('div')
      .attr('class', styles.stat)
      .html(`${i18next.t('payments.amount')}: <strong>${currencySymbol} ${d.sizePrice}</strong>`);

    if (weightUnit === weightUnitsByCompany.KILOGRAM) {
      tooltipContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('stockings.populations.biomass')}: <strong>${biomassKg} kg</strong>`);
    }

    if (weightUnit === weightUnitsByCompany.POUND) {
      tooltipContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('stockings.populations.biomass')}: <strong>${biomassLb} lb</strong>`);
    }
    
    if (weightUnit === weightUnitsByCompany.KILOGRAM_AND_POUND) {
      tooltipContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('stockings.populations.biomass')} (kg): <strong>${biomassKg}</strong>`);
    
      tooltipContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('stockings.populations.biomass')} (lb): <strong>${biomassLb}</strong>`);
    }
  }

  createHistogram (dataset: Dataset[]) {
    const { svg, height } = this;

    this.buildXAxisLabels();
    this.buildAxisY();

    const bandWidth = this.xLabelScale.bandwidth();

    // eslint-disable-next-line
    // @ts-ignore
    this.bars = svg.selectAll('.histogram')
      .data(dataset)
      .enter()
      .append('rect')
      .on('mouseover', (event, d) => {
        this.renderTooltip(d);
      })
      .on('mouseleave', () => {
        this.tooltip.style('display', 'none');
      })
      .attr('class', 'histogram')
      .attr('x', (d: Dataset) => this.xLabelScale(d.label) as number)
      .attr('y', (d: Dataset) => this.y(d.frequency))
      .attr('width', bandWidth)
      .attr('height', (d: Dataset) => height - this.y(d.frequency))
      .style('fill', this.fillBars);

    // eslint-disable-next-line
    // @ts-ignore
    this.xAxisLabelScale = svg.selectAll('.xAxisLabelScale')
      .data([null]) // Un solo dato ficticio para evitar múltiples instancias
      .enter()
      .append('g')
      .attr('class', cx('xAxisLabelScale', styles.axisBottom))
      .attr('transform', `translate(0, ${height})`)
      .call(d3.axisBottom(this.xLabelScale)); // Llamamos al eje D3 para crear el eje
  
    this.xAxisLabelScale
      .selectAll('text') // Estilo para los textos del eje
      .style('text-anchor', 'middle')
      .attr('dy', '8px');

    // eslint-disable-next-line
    // @ts-ignore
    this.textFrequencies = svg.selectAll('.frequencies')
      .data(dataset)
      .enter()
      .append('text')
      .on('mouseover', (event, d) => {
        this.renderTooltip(d);
      })
      .on('mouseleave', () => {
        this.tooltip.style('display', 'none');
      })
      .attr('class', cx('frequencies', styles.frequencies))
      .attr('x', (d: Dataset) => this.getFrecuencyPositionX(d))
      .attr('y', (d: Dataset) => this.getFrecuencyPositionY(d))
      .style('text-anchor', 'middle')
      .html((d: Dataset) => this.getFrecuencyLabel(d));

    // eslint-disable-next-line
    // @ts-ignore
    this.xAxisPriceScale = svg.selectAll('.axisPriceScale')
      .data(dataset)
      .enter()
      .append('text')
      .attr('class', cx('axisPriceScale', styles.axisPrice))
      .attr('x', (d: Dataset) => this.getFrecuencyPositionX(d))
      .attr('y', height + EXTRA_HEIGHT_PRICES_AXIS)
      .style('text-anchor', 'middle')
      .text((d: Dataset) => `${this.currencySymbol} ${d.sizePrice}`);
  }

  refreshHistogram (props: RefreshProps) {
    const { dataset, companyData, currencySymbol, fillBars } = props;
    const { svg, height, tooltip } = this;

    this.dataset = dataset;
    this.companyData = companyData;
    this.currencySymbol = currencySymbol;
    this.fillBars = fillBars;
    
    tooltip.style('display', 'none');

    this.buildXAxisLabels();
    this.buildAxisY();

    const bandWidth = this.xLabelScale.bandwidth();
    
    // eslint-disable-next-line
    // @ts-ignore
    this.bars = svg.selectAll('.histogram')
      .data(dataset);

    this.bars.enter()
      .append('rect')
      .merge(this.bars)
      .on('mouseover', (event, d) => {
        this.renderTooltip(d);
      })
      .on('mouseleave', () => {
        this.tooltip.style('display', 'none');
      })
      .transition()
      .duration(TIME_TRANSITION)
      .attr('class', 'histogram')
      .attr('x', (d: Dataset) => this.xLabelScale(d.label) as number)
      .attr('y', (d: Dataset) => this.y(d.frequency))
      .attr('width', bandWidth)
      .attr('height', (d: Dataset) => height - this.y(d.frequency))
      .style('fill', this.fillBars);

    this.bars.exit()
      .remove();

    // eslint-disable-next-line
    // @ts-ignore
    this.xAxisLabelScale = svg.selectAll('.xAxisLabelScale');
    
    this.xAxisLabelScale.enter()
      .append('g')
      .merge(this.xAxisLabelScale)
      .attr('class', cx('xAxisLabelScale', styles.axisBottom))
      .attr('transform', `translate(0, ${height})`)
      .call(d3.axisBottom(this.xLabelScale)); // Llamamos al eje D3 sobre este grupo
    
    this.xAxisLabelScale
      .selectAll('text') // Seleccionamos los textos generados por el eje
      .style('text-anchor', 'middle')
      .attr('dy', '8px');

    this.xAxisLabelScale.exit()
      .remove();
      
    //update frequency
    // eslint-disable-next-line
    // @ts-ignore
    this.textFrequencies = svg.selectAll('.frequencies')
      .data(dataset);

    this.textFrequencies.enter()
      .append('text')
      .merge(this.textFrequencies)
      .on('mouseover', (event, d) => {
        this.renderTooltip(d);
      })
      .on('mouseleave', () => {
        this.tooltip.style('display', 'none');
      })
      .transition()
      .duration(TIME_TRANSITION)
      .attr('class', cx('frequencies', styles.frequencies))
      .attr('x', (d: Dataset) => this.getFrecuencyPositionX(d))
      .attr('y', (d: Dataset) => this.getFrecuencyPositionY(d))
      .style('text-anchor', 'middle')
      .text((d: Dataset) => this.getFrecuencyLabel(d));

    this.textFrequencies.exit()
      .remove();
    
    // eslint-disable-next-line
    // @ts-ignore
    this.xAxisPriceScale = svg.selectAll('.axisPriceScale')
      .data(dataset);

    this.xAxisPriceScale.enter()
      .append('text')
      .merge(this.xAxisPriceScale)
      .transition()
      .duration(TIME_TRANSITION)
      .attr('class', cx('axisPriceScale', styles.axisPrice))
      .attr('x', (d: Dataset) => this.getFrecuencyPositionX(d))
      .attr('y', height + EXTRA_HEIGHT_PRICES_AXIS)
      .style('text-anchor', 'middle')
      .text((d: Dataset) => `${this.currencySymbol} ${d.sizePrice}`);

    this.xAxisPriceScale.exit()
      .remove();
  }

  buildXAxisLabels () {
    const { dataset, width } = this;
  
    this.xLabelScale = d3.scaleBand()
      .range([0, width])
      .domain(dataset.map((d) => d.label))
      .padding(X_AXIS_PADDING);
  }
  
  buildAxisY () {
    const { dataset, height } = this;
    let maxDomain: number = d3.max(dataset, (d) => d.frequency) || 0;
    maxDomain = maxDomain <= 0 ? 10 : maxDomain;
  
    this.y = d3.scaleLinear()
      .domain([0, maxDomain])
      .range([height, 0]);
  }
}