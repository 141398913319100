import i18next from 'i18next';
import { groupBy } from 'lodash';
import seedrandom from 'seedrandom';

import { Analysis } from '../Analysis/interfaces';
import { CommercialSizeRange } from '../Company/Packers/interfaces';
import { convertKilogramsToPounds } from '../../helpers/stocking.helpers';
import { getCurrentElementHeight, getCurrentElementWidth } from '../../utils/dimensions';
import { calcYieldPorcentageTail, calcYieldPorcentageWaste } from '../../helpers/commercial-size-price-table';
import { commercialSizeTypes, DEFAULT_STAGE_MAX, roundFourDecimals, weightUnits, weightUnitsByCompany } from '../../config/commons';
import { EXTRA_VALUE_AVERAGE_WEGIHT, generateCommercialSizeRanges, getClickableMinValue, getMaxValueDisplay, MIN_VALUE_DISPLAY_GROW_OUT, processCommercialSizeRanges } from '../../helpers/commercial-size.helpers';

import { BuildAndGetFirstPredictionParams, BuildGrowthSizesParams, CalcDataSourceParams, CheckValidInputsProps, CommercialSizeData, Dataset, DataSourceByStage, GrowOutSize, HistogramData, PackersWithCommercialSizes, PocByPacker, Point, PredictionPoint } from './interfaces';

const POWER_BASE = 5.83;
const POWER_EXPONENT = -0.407;
const DAYS_OF_THE_WEEK = 7;

export const valueTypes = {
  PRESENT: 'PRESENT',
  NET: 'NET',
};

export const dataTypes = {
  CHART: 'CHART',
  TABLE: 'TABLE',
};

export const chartParameters = {
  POC: 'POC',
  WEIGHT: 'WEIGHT',
  BIOMASS: 'BIOMASS',
  BIOMASS_KG: 'BIOMASS_KG',
  BIOMASS_LB: 'BIOMASS_LB',
  CORRECTED_FOOD: 'CORRECTED_FOOD',
  POTENTIAL_GAIN: 'POTENTIAL_GAIN',
  POTENTIAL_INCOME: 'POTENTIAL_INCOME',
  TOTAL_ACCUMULATED_COST: 'TOTAL_ACCUMULATED_COST',
};

export const FEEDING_STRATEGY = {
  HIGH: 1.05,
  NORMAL: 1,
  LOW: 0.95,
};

interface HeightProps {
  refStockingContainer: React.RefObject<HTMLDivElement>;
  refIndicatorContainer: React.RefObject<HTMLDivElement>;
  refMiniChartsContainer: React.RefObject<HTMLDivElement>;
  refOptions: React.RefObject<HTMLDivElement>;
}

interface WidthProps {
  inputsContainer?: React.RefObject<HTMLDivElement>;
  refPackerContainer?: React.RefObject<HTMLDivElement>;
}

export const getHeightOfTheOtherElements = (props: HeightProps) => {
  const { refStockingContainer, refIndicatorContainer, refMiniChartsContainer, refOptions } = props;

  const headerHeight = 64;
  const extraHeight = 60;

  const value = headerHeight + extraHeight + getCurrentElementHeight(refStockingContainer) + getCurrentElementHeight(refIndicatorContainer) + getCurrentElementHeight(refMiniChartsContainer) + getCurrentElementHeight(refOptions);
  return value;
};

export const getWidthOfTheOtherElements = (props: WidthProps) => {
  const { inputsContainer, refPackerContainer } = props;
  const inputsContainerWidth = inputsContainer ? getCurrentElementWidth(inputsContainer) : 0;
  const packerContainerWidth = refPackerContainer ? getCurrentElementWidth(refPackerContainer) : 0;
  

  const extraWidth = 90;
  let sidebarWidth = 0;

  if (window.innerWidth > 950) {
    sidebarWidth = 80;
  }

  if (window.innerWidth > 1420) {
    sidebarWidth = 240;
  }

  return sidebarWidth + extraWidth + inputsContainerWidth + packerContainerWidth;
};

export const getNumberTicks = (props: { firstStage: number; lastStage: number; isExcluding?: boolean; }) => {
  const { firstStage, lastStage, isExcluding } = props;

  if (!isExcluding) {
    const stagesDiff = (lastStage - firstStage) / 2;
    return stagesDiff < DEFAULT_STAGE_MAX ? stagesDiff : 16;
  }

  const stagesDiff = (lastStage - firstStage);
  return stagesDiff < DEFAULT_STAGE_MAX ? stagesDiff : 16;
};

function randomNormal (rng: seedrandom.PRNG, weightPredicted: number, sigma: number) {
  const u1 = rng();
  const u2 = rng();
  const z0 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2); // Transformed from Box-Muller
  return (z0 * sigma) + weightPredicted;
}

export const getRandomWeights = (params: { uniformity: number; averageWeight: number; size?: number }) => {
  const { size = 150, uniformity, averageWeight } = params;
  const rng = seedrandom('1');
  
  const cv = 100 - uniformity;
  const sigma = (cv / 100) * averageWeight;

  return Array.from({ length: size }, () => randomNormal(rng, averageWeight, sigma));
};

/* eslint-disable max-depth*/
export const classifyGrowOutSizes = (ranges: CommercialSizeRange[], individualWeights: number[]) => {
  const constantToConvert = 1000;
  const length = ranges.length + 1;
  const values: number[] = Array(length).fill(0);

  for (const weight of individualWeights) {
    const convertedWeight = weight / constantToConvert;
    let rangeIndex = -1;

    for (const item of ranges) {
      const { averageMinWeight, averageMaxWeight } = item;
      rangeIndex++;

      if (rangeIndex === 0 && convertedWeight >= averageMinWeight) {
        values[rangeIndex]++;
        break;
      }

      if (rangeIndex === ranges.length - 1 && convertedWeight < (averageMaxWeight + EXTRA_VALUE_AVERAGE_WEGIHT)) {
        values[rangeIndex]++;
        break;
      }

      if (convertedWeight >= averageMinWeight && convertedWeight < (averageMaxWeight + EXTRA_VALUE_AVERAGE_WEGIHT)) {
        values[rangeIndex]++;
        break;
      }
    }
  }

  return values;
};

const getDataSourceByStagePoint = (params: { analysesByStage: Analysis[]; }) => {
  let { analysesByStage } = params;
  const stage = analysesByStage[0].inputData.stage;

  if (analysesByStage.length > 1) {
    analysesByStage = analysesByStage.sort((a, b) => b.resultData.averageWeight - a.resultData.averageWeight);
  }

  const points: Point[] = [];
  let totalWeight = 0;
  let analysisCount = 0;

  for (const analysis of analysesByStage) {
    const { _id, code, createdAt, inputData, resultData, excludedFromPrediction } = analysis;
    const { uniformity, averageWeight } = resultData;
    const y = roundFourDecimals(averageWeight / 1000);
    const point: Point = { _id, code, createdAt, x: inputData.stage, y, uniformity, excludedFromPrediction } as Point;
    points.push(point);
    totalWeight += excludedFromPrediction ? 0 : averageWeight;
    analysisCount += excludedFromPrediction ? 0 : 1;
  }

  // because divide any number to 0, gives an error
  const meanWeight = (analysisCount === 0) ? 0 : roundFourDecimals((totalWeight / analysisCount) / 1000);

  const dataSourceByStagePoint: DataSourceByStage = { x: stage, y: meanWeight, points };
  return dataSourceByStagePoint;
};

const generateDataset = (props: {growOutSize: GrowOutSize; commercialSizeType: string; animals: number; totalBiomass: number; yieldPercentaje: number; commercialSizeRanges: CommercialSizeRange[] }) => {
  const { growOutSize, commercialSizeType, totalBiomass, animals, yieldPercentaje, commercialSizeRanges } = props;

  const dataset: Dataset[] = [];
  let incomeByYield = 0;

  for (let index = 0; index < growOutSize.values.length - 1; index++) {
    const frequency = growOutSize.values[index];
    const label = growOutSize.labels[index];
    const sizePrice = growOutSize.prices[index];
    
    const percent = roundFourDecimals((frequency * 100) / animals);
    const biomass = roundFourDecimals(totalBiomass * percent / 100);
    const biomassLb = roundFourDecimals(convertKilogramsToPounds(biomass, false));
  
    let pricePerWeightUnit = 0;
    if (commercialSizeType === commercialSizeTypes.GROW_OUT_WHOLE) {
      pricePerWeightUnit = (biomass * yieldPercentaje / 100) * sizePrice;
    }
  
    if (commercialSizeType === commercialSizeTypes.GROW_OUT_TAIL) {
      pricePerWeightUnit = (biomassLb * yieldPercentaje / 100) * sizePrice;
    }
  
    incomeByYield += pricePerWeightUnit;
  
    dataset.push({
      index,
      frequency,
      label,
      percent,
      biomass,
      biomassLb,
      sizePrice,
      pricePerWeightUnit,
      commercialSizeRanges,
    });
  }

  return {
    dataset,
    incomeByYield,
    commercialSizeType,
  };
};

const processGrowthSize = (props: {type: string; yieldPercent: number; params: BuildGrowthSizesParams}) => {
  const { type, yieldPercent, params } = props;

  if (yieldPercent === 0) {
    return null;
  }

  const { biomass: totalBiomass, randomWeights, animals, packerInfo } = params;
  
  const weightUnit =
    type === commercialSizeTypes.GROW_OUT_WHOLE
      ? weightUnitsByCompany.KILOGRAM
      : weightUnitsByCompany.POUND;

  const maxValueDisplay = getMaxValueDisplay({ commercialSizeType: type });
  const commercialSizes =
    type === commercialSizeTypes.GROW_OUT_WHOLE
      ? packerInfo.commercialSizes.whole
      : packerInfo.commercialSizes.tail;

  const commercialSizeRanges = generateCommercialSizeRanges({
    commercialSizes,
    minValueDisplay: MIN_VALUE_DISPLAY_GROW_OUT,
    maxValueDisplay,
    weightUnit,
  });

  const values = classifyGrowOutSizes(commercialSizeRanges, randomWeights);
  const processedDataSource = processCommercialSizeRanges({
    ranges: commercialSizeRanges,
    maxValueDisplay,
    commercialSizeType: type,
    clickableMinValue: getClickableMinValue({ commercialSizeType: type }),
  });

  const labels = processedDataSource.map((item) => item.commercialSizeLabel);
  const prices = type === commercialSizeTypes.GROW_OUT_WHOLE ? packerInfo.prices.whole : packerInfo.prices.tail;

  const dataset = generateDataset({
    animals,
    commercialSizeRanges,
    commercialSizeType: type,
    totalBiomass,
    yieldPercentaje: yieldPercent,
    growOutSize: { labels, values, prices: prices || [] },
  });

  return dataset;
};

const buildGrowthSizes = (params: BuildGrowthSizesParams) => {
  const { biomass: totalBiomass, randomWeights, packerInfo } = params;
  const datasets: Record<string, { dataset: Dataset[]; incomeByYield: number; }> = {};

  const wholeYieldPercent = packerInfo.yield?.whole || 0;
  const tailYieldPercentOriginal = packerInfo.yield?.tail || 0;
  let tailYieldPercent = tailYieldPercentOriginal;

  if (tailYieldPercent !== 0) {
    tailYieldPercent = calcYieldPorcentageTail({
      whole: Number(wholeYieldPercent),
      tail: Number(tailYieldPercent),
    });
  }

  const processedData = [
    processGrowthSize({ type: commercialSizeTypes.GROW_OUT_WHOLE, yieldPercent: wholeYieldPercent, params }),
    processGrowthSize({ type: commercialSizeTypes.GROW_OUT_TAIL, yieldPercent: tailYieldPercent, params }),
  ].filter(Boolean) as { dataset: Dataset[]; incomeByYield: number; commercialSizeType: string; }[];

  processedData.forEach((data) => {
    datasets[data.commercialSizeType] = data;
  });

  const wastePrice = packerInfo.waste?.price || 0;
  const wastePercent = calcYieldPorcentageWaste({ whole: Number(wholeYieldPercent), tail: Number(tailYieldPercentOriginal) });

  const incomeByWaste = (totalBiomass * wastePercent / 100) * wastePrice;
  const incomeByYield = processedData.reduce((acc, data) => acc + data.incomeByYield, 0) + incomeByWaste;
  const potentialIncome = roundFourDecimals(incomeByYield);

  const histogram: HistogramData = {
    whole: datasets[commercialSizeTypes.GROW_OUT_WHOLE]?.dataset,
    tail: datasets[commercialSizeTypes.GROW_OUT_TAIL]?.dataset,
    income: {
      whole: datasets[commercialSizeTypes.GROW_OUT_WHOLE]?.incomeByYield,
      tail: datasets[commercialSizeTypes.GROW_OUT_TAIL]?.incomeByYield,
      waste: incomeByWaste,
    },
    yield: {
      whole: wholeYieldPercent,
      tail: tailYieldPercentOriginal,
    },
  };

  return { histogram, potentialIncome, randomWeights };
};

const buildAndGetFirstPrediction = (params: BuildAndGetFirstPredictionParams) => {
  const {
    dataSourceByStage, initialPopulation, survival, dailyFeeding, costPerVolumeDay,
    volume, packersWithCommercialSizes,
  } = params;

  const biomass = roundFourDecimals(initialPopulation * dataSourceByStage.y / 1000);
  const biomassLb = roundFourDecimals(convertKilogramsToPounds(biomass, false));
  const points: Point[] = dataSourceByStage?.points ? dataSourceByStage.points as Point[] : [];
  const noExcludedPoints = points.filter(item => 'excludedFromPrediction' in item && !item.excludedFromPrediction);
  const uniformitySum = noExcludedPoints.reduce((sum, point) => sum + point.uniformity, 0);
  const uniformity = roundFourDecimals(uniformitySum / noExcludedPoints.length);
  const firstPoint = dataSourceByStage?.points?.[0];
  const predictionDate = firstPoint && 'createdAt' in firstPoint ? firstPoint.createdAt : '';
  const correctedFoodQuantity = roundFourDecimals(dailyFeeding);
  const modelFoodQuantity = roundFourDecimals(Math.pow(POWER_BASE * dataSourceByStage.y, POWER_EXPONENT) * biomass);
  const accumulatedCost = costPerVolumeDay * dataSourceByStage.x * volume;
  const balancedAccumulatedCost = params.accumulatedCost;
  const totalAccumulatedCost = accumulatedCost + balancedAccumulatedCost;

  const animals = 150;
  const randomWeights = getRandomWeights({ uniformity, averageWeight: (dataSourceByStage.y * 1000), size: animals });

  const commercialSizes = generateCommercialSizeData({ packersWithCommercialSizes, totalAccumulatedCost, biomass, animals, pocByPackers: [], volume, stage: dataSourceByStage.x, randomWeights });
  const pocByPackers: PocByPacker[] = commercialSizes.pocByPackers;
  const commercialSizeData: CommercialSizeData[] = commercialSizes.commercialSizeData;

  const firstPrediction: PredictionPoint = {
    x: dataSourceByStage.x, y: dataSourceByStage.y,
    predictionDate,
    uniformity,
    biomass, biomassLb,
    population: initialPopulation,
    survival,
    correctedFoodQuantity, modelFoodQuantity,
    accumulatedCost,
    balancedAccumulatedCost,
    totalAccumulatedCost,
    commercialSizeData,
  };
  return { firstPrediction, pocByPackers };
};

const generateCommercialSizeData = (props: {packersWithCommercialSizes: PackersWithCommercialSizes[]; stage: number; randomWeights: number[]; totalAccumulatedCost: number; volume: number; pocByPackers: PocByPacker[]; animals: number; biomass: number; }) => {
  const { packersWithCommercialSizes, stage, randomWeights, totalAccumulatedCost, volume, biomass, animals, pocByPackers } = props;

  const commercialSizeData: CommercialSizeData[] = [];
  const initializePocByPacker = pocByPackers.length === 0;

  for (let index = 0; index < packersWithCommercialSizes.length; index++) {
    const packerInfo = packersWithCommercialSizes[index];
    const pocByPacker = pocByPackers[index];
    const { packerId, packerName } = packerInfo;
    const { potentialIncome, histogram } = buildGrowthSizes({ randomWeights, biomass, animals, packerInfo });
    const potentialGain = (potentialIncome - totalAccumulatedCost);
    const potentialGainByDayHectarea = (potentialIncome - totalAccumulatedCost) / volume / stage;
    
    commercialSizeData.push({
      x: stage,
      packerId,
      packerName,
      histogram,
      potentialGain,
      potentialIncome,
      potentialGainByDayHectarea,
    });

    if (initializePocByPacker) {
      pocByPackers.push({
        packerId,
        packerName,
        x: stage,
        poc: potentialGainByDayHectarea,
      });

      continue;
    }

    const prevPoc = pocByPacker.poc;
    if (potentialGainByDayHectarea > prevPoc) {
      pocByPackers[index].poc = potentialGainByDayHectarea;
      pocByPackers[index].x = stage;
    }
  }

  return {
    commercialSizeData,
    pocByPackers
  };
};

export const calcDataSource = (params: CalcDataSourceParams) => {
  const {
    dataSource, isExcluding, firstStage, packersWithCommercialSizes,
    dailyFeeding, costPerVolumeDay, initialPopulation, volume,
    harvestsAndTransfers, mortality, animalsSown, foodPricePerKg,
  } = params;

  const dataSourceByStage: DataSourceByStage[] = [];
  const allPredictions: PredictionPoint[] = [];
  const allPoints: (Point | PredictionPoint)[] = [];

  if (dataSource.allAnalysis.length === 0 || dataSource.predictions.length === 0) {
    return { dataSourceByStage, allPredictions, allPoints, pocByPackers: [] };
  }

  let analysesToGroup = dataSource.allAnalysis;

  if (!isExcluding) {
    analysesToGroup = dataSource.allAnalysis.filter(item => item.inputData.stage >= firstStage);
  }

  if (analysesToGroup.length === 0) {
    return { dataSourceByStage, allPredictions, allPoints, pocByPackers: [] };
  }

  const analysisGroupByStage = groupBy(analysesToGroup, 'inputData.stage');
  
  for (const key in analysisGroupByStage) {
    if (Object.prototype.hasOwnProperty.call(analysisGroupByStage, key)) {
      const dataSourceByStagePoint = getDataSourceByStagePoint({ analysesByStage: analysisGroupByStage[key] });
      dataSourceByStage.push(dataSourceByStagePoint);
    }
  }

  const animals = 150;
  const lastItem = dataSourceByStage[dataSourceByStage.length - 1];
  const firstPredictionResult = buildAndGetFirstPrediction({
    packersWithCommercialSizes,
    accumulatedCost: params.accumulatedCost,
    costPerVolumeDay,
    dailyFeeding,
    dataSourceByStage: lastItem,
    initialPopulation,
    survival: params.survival,
    volume,
  });
  
  const { firstPrediction } = firstPredictionResult;
  let pocByPackers: PocByPacker[] = firstPredictionResult.pocByPackers;
  const firstPredictionStage = firstPrediction.x;
  const firstPredictionWeight = firstPrediction.y;
  const firstPredictionUniformity = firstPrediction.uniformity;
  allPredictions.push(firstPrediction);

  const lastPrediction = dataSource.predictions[dataSource.predictions.length - 1];
  const lastPredictionWeight = lastPrediction.resultData.averageWeight / 1000;
  const lastPredictionUniformity = lastPrediction.resultData.uniformity;
  const lastPredictionStage = lastPrediction.inputData.stage;

  let prevPrediction = firstPrediction;
  let stage = firstPredictionStage + 1;

  while (stage < lastPredictionStage) {
    const { y: prevAverageWeight, uniformity: prevUniformity, predictionDate: prevPredictionDate } = prevPrediction;
    const prevPopulation = prevPrediction.population as number;
    const prevCorrectedFoodQuantity = prevPrediction.correctedFoodQuantity as number;
    const prevModelFoodQuantity = prevPrediction.modelFoodQuantity as number;
    const prevBalancedAccumulatedCost = prevPrediction.balancedAccumulatedCost as number;
  
    const uniformity = roundFourDecimals(prevUniformity + ((lastPredictionUniformity - firstPredictionUniformity) / (lastPredictionStage - firstPredictionStage)));
    const averageWeight = roundFourDecimals(prevAverageWeight + ((lastPredictionWeight - firstPredictionWeight) / (lastPredictionStage - firstPredictionStage)));
    const population = Math.round(prevPopulation - (prevPopulation * mortality / (DAYS_OF_THE_WEEK * 100)));
    const biomass = roundFourDecimals(population * averageWeight / 1000);
    const biomassLb = roundFourDecimals(convertKilogramsToPounds(biomass, false));
    const survival = roundFourDecimals((population + harvestsAndTransfers) / animalsSown * 100);
    const modelFoodQuantity = roundFourDecimals(Math.pow(POWER_BASE * averageWeight, POWER_EXPONENT) * biomass);
    const correctedFoodQuantity = roundFourDecimals(prevCorrectedFoodQuantity * modelFoodQuantity / prevModelFoodQuantity);
    const accumulatedCost = costPerVolumeDay * stage * volume;
    const balancedAccumulatedCost = prevBalancedAccumulatedCost + (correctedFoodQuantity * foodPricePerKg);
    const totalAccumulatedCost = accumulatedCost + balancedAccumulatedCost;

    const randomWeights = getRandomWeights({ uniformity, averageWeight: (averageWeight * 1000), size: animals });

    const commercialSizes = generateCommercialSizeData({ packersWithCommercialSizes, totalAccumulatedCost, biomass, animals, pocByPackers, volume, stage, randomWeights });
    const commercialSizeData: CommercialSizeData[] = commercialSizes.commercialSizeData;
    pocByPackers = commercialSizes.pocByPackers;

    const predictionDate = new Date(prevPredictionDate);
    predictionDate.setDate(predictionDate.getDate() + 1);
  
    const newPrediction: PredictionPoint = {
      x: stage, y: averageWeight,
      predictionDate: predictionDate.toISOString(),
      uniformity,
      biomass, biomassLb,
      population,
      survival,
      modelFoodQuantity, correctedFoodQuantity,
      accumulatedCost,
      balancedAccumulatedCost,
      totalAccumulatedCost,
      commercialSizeData,
    };
    const dataSourceByStagePoint: DataSourceByStage = { x: stage, y: averageWeight, points: [newPrediction] };
    dataSourceByStage.push(dataSourceByStagePoint);
    allPredictions.push(newPrediction);
    prevPrediction = newPrediction;
    stage += 1;
  }

  const prevPopulation = prevPrediction.population as number;
  const prevCorrectedFoodQuantity = prevPrediction.correctedFoodQuantity as number;
  const prevModelFoodQuantity = prevPrediction.modelFoodQuantity as number;
  const prevBalancedAccumulatedCost = prevPrediction.balancedAccumulatedCost as number;
  const prevPredictionDate = prevPrediction.predictionDate;
  
  const population = Math.round(prevPopulation - (prevPopulation * mortality / (DAYS_OF_THE_WEEK * 100)));
  const biomass = roundFourDecimals(population * lastPredictionWeight / 1000);
  const biomassLb = roundFourDecimals(convertKilogramsToPounds(biomass, false));
  const survival = roundFourDecimals((population + harvestsAndTransfers) / animalsSown * 100);
  const modelFoodQuantity = roundFourDecimals(Math.pow(POWER_BASE * lastPredictionWeight, POWER_EXPONENT) * biomass);
  const correctedFoodQuantity = prevCorrectedFoodQuantity * modelFoodQuantity / prevModelFoodQuantity;
  const accumulatedCost = costPerVolumeDay * stage * volume;
  const balancedAccumulatedCost = prevBalancedAccumulatedCost + (correctedFoodQuantity * foodPricePerKg);
  const totalAccumulatedCost = accumulatedCost + balancedAccumulatedCost;
  const predictionDate = new Date(prevPredictionDate);
  predictionDate.setDate(predictionDate.getDate() + 1);

  const randomWeights = getRandomWeights({ uniformity: lastPredictionUniformity, averageWeight: (lastPredictionWeight * 1000), size: animals });

  const commercialSizes = generateCommercialSizeData({ packersWithCommercialSizes, totalAccumulatedCost, biomass, animals, pocByPackers, volume, stage, randomWeights });
  const commercialSizeData: CommercialSizeData[] = commercialSizes.commercialSizeData;
  pocByPackers = commercialSizes.pocByPackers;

  const newPrediction: PredictionPoint = {
    x: stage, y: lastPredictionWeight,
    predictionDate: predictionDate.toISOString(),
    uniformity: lastPredictionUniformity,
    biomass,
    biomassLb,
    population,
    survival,
    modelFoodQuantity, correctedFoodQuantity,
    accumulatedCost,
    balancedAccumulatedCost,
    totalAccumulatedCost,
    commercialSizeData,
  };
  
  const dataSourceByStagePoint: DataSourceByStage = { x: stage, y: lastPredictionWeight, points: [newPrediction] };
  dataSourceByStage.push(dataSourceByStagePoint);
  allPredictions.push(newPrediction);

  for (const item of dataSourceByStage) {
    const { points } = item;
    if (!points || points.length === 0) {
      continue;
    }
    for (const point of points) {
      allPoints.push(point);
    }
  }

  return { dataSourceByStage, allPredictions, allPoints, pocByPackers };
};

export const checkValidInputs = (props: CheckValidInputsProps) => {
  const { survival, mortality, weeklyFeeding, foodPricePerKg, costPerVolumeDay, accumulatedCost } = props;
  
  return (
    mortality > 0 && weeklyFeeding > 0 && foodPricePerKg > 0 && costPerVolumeDay > 0 &&
    accumulatedCost > 0 && survival > 0
  );
};

export const getLabelsAxisY = (props: { chartParameter?: string; weightUnit: string; currencySymbol: string; }) => {
  const { chartParameter, weightUnit, currencySymbol } = props;

  switch (chartParameter) {
    case chartParameters.WEIGHT:
      return `${i18next.t('optimalHarvestPoint.averageWeight')} ${weightUnits.G}`;

    case chartParameters.BIOMASS:
    case chartParameters.BIOMASS_KG:
      if (weightUnit === weightUnitsByCompany.POUND) {
        return `${i18next.t('optimalHarvestPoint.biomass')} (${weightUnits.LB})`;
      }

      return `${i18next.t('optimalHarvestPoint.biomass')} (${weightUnits.KG})`;
      
    case chartParameters.CORRECTED_FOOD:
      return `${i18next.t('optimalHarvestPoint.food')}`;
      
    case chartParameters.POC:
      return `${i18next.t('optimalHarvestPoint.profitPerDay')} (${currencySymbol})`;
      
    case chartParameters.POTENTIAL_INCOME:
      return `${i18next.t('optimalHarvestPoint.potentialIncome')} (${currencySymbol})`;
      
    case chartParameters.TOTAL_ACCUMULATED_COST:
      return `${i18next.t('optimalHarvestPoint.costs')} (${currencySymbol})`;

    default:
      return '';
  }
};

export const sortPocs = (pocByPackers: PocByPacker[]): PocByPacker[] => {
  return pocByPackers
    .sort((a, b) => b.poc - a.poc);
};

export const getTopNPocs = (pocByPackers: PocByPacker[], n: number): PocByPacker[] => {
  return pocByPackers
    .slice(0, n);
};

export const calculatePresentValue = (props: {futureValue: number, annualRate: number, days: number;}): number => {
  const { futureValue, annualRate, days } = props;
  
  if (annualRate < 0 || days < 0) {
    return 0;
  }

  const dailyRate = Math.pow(1 + annualRate, 1 / 365) - 1;
  const presentValue = futureValue / Math.pow(1 + dailyRate, days);
  return presentValue;
};
