import { useTranslation } from 'react-i18next';
import CalendarHeatmap, { TooltipDataAttrs } from 'react-calendar-heatmap';

import './HeatMap.scss';

export const HeatMap = () => {
  const [t] = useTranslation();

  const today = new Date();

  const shiftDate = (date: Date, numDays: number) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + numDays);
    return newDate;
  };
  
  const getRange = (count: number) => {
    return Array.from({ length: count }, (_, i) => i);
  };
  
  const getRandomInt = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  
  const randomValues = getRange(200).map(index => {
    return {
      date: shiftDate(today, -index),
      count: getRandomInt(1, 3),
    };
  });
  
  return (
    <div>
      <CalendarHeatmap
        startDate={shiftDate(today, -60)}
        endDate={today}
      
        values={randomValues}
        classForValue={value => {
          if (!value) {
            return 'color-empty';
          }

          return `color-scale-${value.count}`;
        }}
        // tooltipDataAttrs={value => {
        //   return {
        //     'data-tip': `${value?.date?.toISOString().slice(0, 10)} has count: ${value?.count}`,
        //   };
        // }}
        tooltipDataAttrs={(value) => (
        {
          'data-tip': value?.date ? `Fecha: ${value.date} | Conteo: ${value.count}` : '',
        }as TooltipDataAttrs)
        }
        monthLabels={[
          t('months.january'), t('months.february'), t('months.march'), t('months.april'), t('months.may'), t('months.june'),
          t('months.july'), t('months.august'), t('months.september'), t('months.octuber'), t('months.november'), t('months.december'),
        ]}
        gutterSize={1}
        //showMonthLabels={false}
        //showOutOfRangeDays={true}
        weekdayLabels={[t('days.sunday'), t('days.monday'), t('days.tuesday'), t('days.wednesday'), t('days.thurday'), t('days.friday'), t('days.saturday')]}
        showWeekdayLabels={true}
        //horizontal
        onClick={value => alert(`Clicked on value with count: ${value?.count}`)}
      />
      {/* <ReactTooltip /> */}
    </div>
  );
};
