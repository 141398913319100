import { useTranslation } from 'react-i18next';

import { chartParameters } from '../helpers';

import { Chart } from './Chart';
import { MiniChart } from './MiniChart';
import styles from './MiniCharts.module.scss';

export const MiniCharts = () => {
  const [t] = useTranslation();

  const charts = [
    {
      parameter: chartParameters.WEIGHT,
      title: t('optimalHarvestPoint.weight'),
    },
    {
      parameter: chartParameters.BIOMASS_KG,
      title: t('optimalHarvestPoint.biomass'),
    },
    {
      parameter: chartParameters.TOTAL_ACCUMULATED_COST,
      title: t('optimalHarvestPoint.costs'),
    },
    {
      parameter: chartParameters.POTENTIAL_INCOME,
      title: t('optimalHarvestPoint.income'),
    },
    {
      parameter: chartParameters.CORRECTED_FOOD,
      title: t('optimalHarvestPoint.food'),
    },
  ];

  return (
    <>
      <div className={styles.graphs}>
        {charts.map((chart) => (
          <MiniChart chartParameter={chart.parameter} title={chart.title} />
        ))}
        
        <Chart />
      </div>
    </>
  );
};
