import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Icon from '../../../common/components/Icon/Icon';
import { Store } from '../../../state/store.interfaces';
import { getCurrentTheme } from '../../../helpers/theme';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import * as optimalHarvestPointSlice from '../optimalHarvestPointSlice';
import { MiniForecastMetricD3 } from '../../../common/components/charts/OptimalHarvestPoint/MiniForecastMetricD3';

import styles from './MiniChart.module.scss';

interface Props {
  chartParameter: string;
  title: string;
}

export const MiniChart = (props: Props) => {
  const { chartParameter, title } = props;

  const dispatch = useDispatch();
  const theme = getCurrentTheme();

  const refChart = useRef<HTMLDivElement>(null);
  const chart = useRef<MiniForecastMetricD3 | null>(null);

  const { company: selectedCompany } = useSelector((state: Store) => state.header);
  const {
    firstStage,
    lastStage,
    allPredictions,
    predictionSelected,
    pocPoint,
  } = useSelector((state: Store) => state.optimalHarvestPoint);

  const point = predictionSelected || pocPoint;

  const height = 100;
  const width = 140;

  useEffect(() => {
    return () => {
      chart.current = null;
    };
  }, [dispatch]);

  useEffect(() => {
    if (allPredictions.length === 0 || !point?.packerId) {
      return;
    }

    if (chart.current) {
      chart.current.refreshChart({
        allPredictions,
        companyData: selectedCompany,
        chartParameter,
        firstStage,
        lastStage,
        packerId: point?.packerId,
      });
      return;
    }

    chart.current = new MiniForecastMetricD3({
      container: refChart.current,
      allPredictions,
      height,
      width,
      firstStage,
      lastStage,
      companyData: selectedCompany,
      chartParameter,
      packerId: point?.packerId,
    });
  }, [allPredictions, firstStage, lastStage, selectedCompany, chartParameter, predictionSelected, pocPoint, point]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <LrvText className={styles.title} text={title} theme={theme} />

        <div
          className={styles.iconContainer}
          onClick={() => {
            dispatch(optimalHarvestPointSlice.setShowMiniChartModal(true));
            dispatch(optimalHarvestPointSlice.setMiniChartParameter(chartParameter));
          }}
        >
          <Icon name='expand-diagonal' theme={theme} className={styles.icon} />
        </div>
      </div>
      <div
        ref={refChart}
        className={styles.chart}
      />
    </div>
  );
};
