import React from 'react';

import styles from './ProgressBar.module.scss';

interface Props {
  percentage: number;
  color: string;
}

export const ProgressBar = (props: Props) => {
  const { color, percentage } = props;

  return (
    <div className={styles.progressContainer}>
      <div className={styles.progressBar} style={{ width: `${percentage}%`, backgroundColor: color }} />
    </div>
  );
};

