import { useEffect, useState } from 'react';

import { roundTwoDecimals } from '../../config/commons';

interface Props {
  value: number;
}

export const AnimatedValue = (props: Props) => {
  const { value } = props;

  const [displayValue, setDisplayValue] = useState(0);


  useEffect(() => {
    const duration = 250;
    const startTime = performance.now();

    const animate = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      setDisplayValue(roundTwoDecimals(progress * value));

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, [value]);

  return (
    <div>{displayValue}</div>
  );
};
