import { useDispatch, useSelector } from 'react-redux';

import { ProgressBar } from '../ProgressBar';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import * as optimalHarvestPointSlice from '../optimalHarvestPointSlice';
import { CommercialSizePriceTable } from '../../Company/Packers/TablePrices/interfaces';
import { getPointColor } from '../../../common/components/charts/OptimalHarvestPoint/helpers';

import styles from './Packers.module.scss';

interface Props {
  theme?: 'light' | 'dark';
}

export const Packers = (props: Props) => {
  const { theme = 'light' } = props;

  const dispatch = useDispatch();
  const { allPackers: packers, packersWithCommercialSizes } = useSelector((state: Store) => state.optimalHarvestPoint);

  const extraWeight = 20;
  const maxWeight = Math.max(...packers.map((packer) => packer.poc)) + extraWeight;

  return (
    <div className={styles.container}>
      <div className={styles.title}>Empacadoras</div>

      {packers.map((packer, index) => (
        <div key={packer.packerName} className={styles.packer}>
          <div className={styles.packerInfo}>
            <div className={styles.packerName}>
              {packer.packerName} - {Math.round(packer.poc)} kg
            </div>

            <div
              className={styles.iconContainer}
              onClick={() => {
                const packerWithCommercialSize = packersWithCommercialSizes.find((item) => item.packerId === packer.packerId);
                if (!packerWithCommercialSize) {
                  return;
                }

                const commercialSize: CommercialSizePriceTable = {
                  _id: packerWithCommercialSize.commercialSizePriceTableId,
                  date: packerWithCommercialSize.date,
                  commercialSizes: packerWithCommercialSize.commercialSizes,
                  prices: packerWithCommercialSize.prices,
                  waste: packerWithCommercialSize.waste,
                  yield: packerWithCommercialSize.yield,
                  companyId: packerWithCommercialSize.companyId,
                  packerId: packerWithCommercialSize.packerId,
                  packerName: packerWithCommercialSize.packerName,
                  commercialSizeId: packerWithCommercialSize.commercialSizeId,
                  paymentDelayDays: packerWithCommercialSize.paymentDelayDays,
                };
  
                dispatch(optimalHarvestPointSlice.setCommercialSizePriceTable(commercialSize));
                dispatch(optimalHarvestPointSlice.setShowUpdatePriceTableModal(true));
              }}
            >
              <Icon name='pencil' theme={theme} className={styles.icon} />
            </div>
          </div>
          <ProgressBar percentage={(packer.poc / maxWeight) * 100} color={getPointColor(index)} />
        </div>
      ))}
    </div>
  );
};
