import { roles } from './commons';

export const pathsPerms = {
  '/clients': [roles.FINANCE, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT],
  '/users': [roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/units': [roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/units/:unitId': [roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/units/:unitId/modules/:moduleId': [roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/production/insights': [roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/production/parameters': [roles.PARAMETERIST, roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/production/harvests': [roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/production/stockings': [roles.PARAMETERIST, roles.CLIENT_OPERATOR, roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/production/stockings/:id': [roles.PARAMETERIST, roles.CLIENT_OPERATOR, roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/production/analysis/:id': [roles.CLIENT_OPERATOR, roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/production/optimal-harvest-point': [roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/reports/growth-delta': [roles.CLIENT_OPERATOR, roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/reports/survival-rate': [roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/reports/current-state': [roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/reports/performance': [roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/reports/quadrant': [roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/reports/laboratory': [roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/reports/parameter-state': [roles.PARAMETERIST, roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/reference-curves': [roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/settings': [roles.PARAMETERIST, roles.CLIENT_OPERATOR, roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.PROMOTER, roles.BUSINESS_MANAGER, roles.SALES, roles.SALES_MANAGER, roles.FINANCE, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/payments': [roles.FINANCE, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.PARTIAL_ADMIN],
  '/payment/manage/:id': [roles.FINANCE, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.PARTIAL_ADMIN],
  '/unauthorized': [roles.CLIENT_OPERATOR, roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.FINANCE, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/dashboard': [roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/genetics/global': [roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.PARTIAL_ADMIN],
  '/genetics/global/:id': [roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.PARTIAL_ADMIN],
  '/genetics/setup': [roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/genetics/insights': [roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/company/administration-history': [roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/company/settings': [roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/company/analysis-card': [roles.CLIENT_OWNER],
  '/company/stocking-parameters': [roles.PARAMETERIST, roles.CLIENT_OWNER],
  '/company/transports': [roles.CLIENT_OWNER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.PARTIAL_ADMIN],
  '/company/observations': [roles.CLIENT_OWNER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.PARTIAL_ADMIN],
  '/company/packers': [roles.CLIENT_OWNER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.PARTIAL_ADMIN],
  '/company/packers/:packerId': [roles.CLIENT_OWNER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.PARTIAL_ADMIN],
  '/company/packers/:packerId/commercial-size/:commercialSizeId': [roles.CLIENT_OWNER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.PARTIAL_ADMIN],
  '/balance-consumption': [roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN],
  '/archive': [roles.CLIENT_OPERATOR, roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/analysis-without-stockings': [roles.CLIENT_MANAGER, roles.CLIENT_OWNER, roles.BUSINESS_MANAGER, roles.PROMOTER, roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT, roles.PARTIAL_ADMIN],
  '/admin': [roles.SALES, roles.SALES_MANAGER, roles.SUPER_ADMIN, roles.TECHNICAL_SUPPORT],
};
