import * as d3 from 'd3';
import cx from 'classnames';
import i18next from 'i18next';

import { getCurrentTheme } from '../../../../helpers/theme';
import { formatLongDateWithZone } from '../../../../utils/date';
import { Company } from '../../../../pages/AppHeader/interfaces';
import { applyThousandsSeparator } from '../../../../utils/strings';
import { chartParameters } from '../../../../pages/OptimalHarvestPoint/helpers';
import { formatter } from '../../../../pages/Sowings/Multiphase/multiphase-helpers';
import { roundOneDecimal, roundTwoDecimals, stockingPhaseTypes, THEME } from '../../../../config/commons';
import { CommercialSizeData, IPoint, PocByPacker, PredictionPoint } from '../../../../pages/OptimalHarvestPoint/interfaces';

import styles from './ForecastMetricD3.module.scss';
import { calculateTooltipBottom, calculateTooltipExtraPaddingLeft, generateLines, getChartLeftPosition, getCommercialSize, getCommercialSizeDataPoints, getHigherValue, getLowestValue, getMarginBottom, getYDomainData, getYValue, renderTickFormat } from './helpers';

let currentStageActive: string;

const TICKS_NUMBER_X = 3;
const TICKS_NUMBER_Y = 8;
const TICK_PADDING = 4;
const TIME_TRANSITION = 300;
const DEFAULT_POINT_SIZE = 3;
const POINT_ACTIVE_SIZE = DEFAULT_POINT_SIZE + 3;

interface Props {
  companyData: Company;
  container: HTMLDivElement | null;
  allPredictions: PredictionPoint[];
  chartParameter: string;
  firstStage: number;
  lastStage: number;
  height: number;
  width: number;
  packerId?: string;
  bestPackers: PocByPacker[];
  currencySymbol: string;
}

interface RefreshProps {
  lastStage: number;
  firstStage: number;
  chartParameter: string;
  allPredictions: PredictionPoint[];
  companyData: Company;
  packerId?: string;
  bestPackers: PocByPacker[];
}

export class ForecastMetricD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGSVGElement, IPoint, null, undefined>;
  groupMain: d3.Selection<SVGGElement, IPoint, null, undefined>;

  pathShadow: d3.Selection<SVGPathElement, PredictionPoint[], null, undefined> = d3.select<SVGPathElement, PredictionPoint[]>(document.createElementNS('http://www.w3.org/2000/svg', 'path'));
  pathLine: d3.Selection<SVGPathElement, PredictionPoint[], null, undefined> = d3.select<SVGPathElement, PredictionPoint[]>(document.createElementNS('http://www.w3.org/2000/svg', 'path'));
  scaleLinearX: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  scaleLinearY: d3.ScaleLinear<number, number, never> = d3.scaleLinear();

  margin = { top: 10, right: 10, bottom: 16, left: 60 };

  allPredictions: PredictionPoint[] = [];
  bestPackers: PocByPacker[] = [];
  currencySymbol: string;

  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  width: number;
  height: number;
  xAxis: d3.Selection<SVGGElement, IPoint, null, undefined> = d3.select<SVGGElement, IPoint>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  yAxis: d3.Selection<SVGGElement, IPoint, null, undefined> = d3.select<SVGGElement, IPoint>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  firstStage = 0;
  lastStage = 0;
  companyData: Company;
  chartParameter: string;
  packerId?: string;

  // eslint-disable-next-line
  constructor(props: Props) {
    const {
      allPredictions,
      bestPackers,
      chartParameter,
      companyData,
      container,
      firstStage,
      lastStage,
      height,
      width,
      packerId,
      currencySymbol,
    } = props;

    this.container = container;
    this.chartParameter = chartParameter;
    this.allPredictions = allPredictions;
    this.bestPackers = bestPackers;
    this.companyData = companyData;
    this.packerId = packerId;
    this.currencySymbol = currencySymbol;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    d3.select(container).select('#tooltipForecastMetric').remove();
    d3.select(container).select('svgForecastMetric').remove();

    this.svg = d3.select<HTMLDivElement | null, IPoint>(container)
      .append('svg')
      .attr('id', 'svgForecastMetric')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    this.groupMain = this.svg
      .append('g')
      .attr('id', 'contentForecastMetric')
      .attr('transform', `translate( ${this.margin.left}, ${this.margin.top} )`)
      .style('pointer-events', 'all');

    this.firstStage = firstStage;
    this.lastStage = lastStage;

    this.createDataPoints();
    this.renderTooltips();
  }

  createDataPoints = () => {
    this.renderLeftAxisTriangle();
    this.buildAxisX();
    this.buildAxisY();
    
    this.createTooltip();
    this.renderLineSelected();

    this.renderLine();
    this.drawXAxis();
    this.drawYAxis();
    this.renderPoints();
  };

  updateAxis = () => {
    const { height } = this;
    
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;
    
    this.buildAxisX();
    this.buildAxisY();

    const axisBottom = d3.axisBottom(this.scaleLinearX)
      .tickFormat((x) => renderTickFormat(x))
      .tickSize(-height)
      .ticks(TICKS_NUMBER_X)
      .tickPadding(TICK_PADDING);

    this.xAxis
      .attr('class', cx(styles.axisX, isLightTheme ? styles.axisLight : styles.axisDark))
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);

    const axisLeft = d3.axisLeft(this.scaleLinearY)
      .tickFormat((d) => d.valueOf() as unknown as string)
      .ticks(TICKS_NUMBER_Y)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.yAxis
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisLeft);
  };

  updateDataPoints = () => {
    this.renderLeftAxisTriangle();
    this.updateAxis();
    this.updateLine();
    this.renderPoints();
  };

  refreshChart = (props: RefreshProps) => {
    const { allPredictions, companyData, chartParameter, firstStage, lastStage, packerId } = props;
    const { tooltip, container } = this;

    this.allPredictions = allPredictions;
    this.firstStage = firstStage;
    this.lastStage = lastStage;
    this.chartParameter = chartParameter;
    this.companyData = companyData;
    this.packerId = packerId;

    d3.select(container).select('#tooltipContent').remove();
    d3.select(container).select('#tooltipExtraPadding').remove();
    
    tooltip.style('display', 'none');

    this.updateDataPoints();
    this.renderTooltips();
    this.renderLineSelected();
  };

  buildAxisX = () => {
    const { width, firstStage, lastStage } = this;

    const minX = firstStage;
    const maxX = lastStage;

    this.scaleLinearX = d3.scaleLinear()
      .domain([minX, maxX])
      .range([0, width]);
  };

  buildAxisY = () => {
    const { height, allPredictions, packerId, chartParameter } = this;
    const { maxY, minY } = getYDomainData({ allPredictions, packerId, chartParameter });

    this.scaleLinearY = d3.scaleLinear()
      .domain([minY, maxY])
      .range([height, 0]);
  };

  renderLine = () => {
    const { groupMain, allPredictions, chartParameter, packerId, scaleLinearX, scaleLinearY } = this;
    const { lineCurve } = generateLines({ scaleLinearX, scaleLinearY, chartParameter, packerId });

    this.pathShadow = groupMain
      .append('path')
      .datum(allPredictions)
      .attr('class', styles.shadowLine)
      .attr('d', lineCurve);

    this.pathLine = groupMain
      .append('path')
      .datum(allPredictions)
      .attr('class', styles.line)
      .attr('d', lineCurve);
  };

  updateLine = () => {
    const { allPredictions, chartParameter, packerId, scaleLinearX, scaleLinearY } = this;
    const { lineCurve } = generateLines({ scaleLinearX, scaleLinearY, chartParameter, packerId });

    this.pathShadow
      .datum(allPredictions)
      .transition()
      .duration(TIME_TRANSITION)
      .attr('d', lineCurve);

    this.pathLine
      .datum(allPredictions)
      .transition()
      .duration(TIME_TRANSITION)
      .attr('d', lineCurve);
  };

  drawXAxis = () => {
    const { height, groupMain } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const axis = d3.axisBottom(this.scaleLinearX)
      .tickFormat((x) => renderTickFormat(x))
      .tickSize(-height)
      .ticks(TICKS_NUMBER_X)
      .tickPadding(TICK_PADDING);

    this.xAxis = groupMain.append('g')
      .attr('id', 'axisX')
      .attr('class', cx(styles.axisX, isLightTheme ? styles.axisLight : styles.axisDark))
      .attr('transform', `translate(0, ${this.height})`)
      .call(axis);
  };

  drawYAxis = () => {
    const { groupMain } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const axis = d3.axisLeft(this.scaleLinearY)
      .tickFormat((d) => d.valueOf() as unknown as string)
      .ticks(TICKS_NUMBER_Y)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.yAxis = groupMain.append('g')
      .attr('id', 'axisY')
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .call(axis);
  }

  getPointsToShow = () => {
    const { allPredictions, chartParameter, bestPackers } = this;
    
    if (chartParameter === chartParameters.POTENTIAL_INCOME) {
      const commercialSizeDataPoints = getCommercialSizeDataPoints({ allPredictions, bestPackers });
      return commercialSizeDataPoints.filter((item) => item.packerId == this.packerId);
    }

    return allPredictions;
  }

  renderPoints = () => {
    const { container, groupMain, scaleLinearX, scaleLinearY, chartParameter } = this;

    const points: IPoint[] = this.getPointsToShow();

    d3.select(container).selectAll('.points').remove();

    const gPoints = groupMain.append('g')
      .attr('class', 'points')
      .attr('cursor', 'default');

    gPoints
      .selectAll('circle')
      .data(points)
      .enter()
      .append('circle')
      .attr('class', (point) => `stage${point.x}`)
      .attr('r', DEFAULT_POINT_SIZE)
      .attr('cx', (point) => scaleLinearX(point.x))
      .attr('cy', (point) => scaleLinearY(getYValue({ point, chartParameter })))
      .attr('fill', '#43A047')
      .attr('stroke', '#43A047')
      .attr('stroke-width', 1);
  };

  renderLeftAxisTriangle = () => {
    const { container, margin } = this;

    const triangleMargins = {
      left: margin.left - 5,
      top: 0,
    };

    d3.select(container).selectAll('.triangle').remove();

    d3.select(container)
      .attr('id', 'triangle')
      .append('div')
      .attr('class', cx('triangle', styles.triangle, styles.predictionTriangle))
      .style('transform', `translate(${triangleMargins.left}px, ${triangleMargins.top}px)`);
  }

  renderLineSelected = () => {
    const { container, groupMain, height } = this;
    d3.select(container).select('#selectedTick').remove();

    groupMain.append('line')
      .attr('id', 'selectedTick')
      .attr('stroke', '#909090')
      .attr('stroke-width', 1)
      .attr('y1', 0)
      .attr('y2', height)
      .style('display', 'none');
  }

  createTooltip = () => {
    this.tooltip = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltipForecastMetric')
      .attr('class', styles.tooltip)
      .style('display', 'none')
      .on('mouseover', () => {
        this.tooltip.style('display', 'block');
        d3.select(this.container).select('#selectedTick').style('display', 'block');
        d3.select(this.container).selectAll(currentStageActive).attr('r', POINT_ACTIVE_SIZE);
      });
  }

  renderTooltips = () => {
    const {
      container,
      scaleLinearX,
      scaleLinearY,
      tooltip,
      firstStage,
      lastStage,
      width,
      height,
      groupMain,
      renderTooltipsContent,
      allPredictions,
      chartParameter,
      currencySymbol,
      packerId,
    } = this;

    const bisect = d3.bisector(function (point: PredictionPoint) {
      return point.x;
    }).left;

    const tooltipContent = tooltip.append('div')
      .attr('id', 'tooltipContent')
      .attr('class', styles.content);

    const tooltipExtraPadding = tooltip.append('div')
      .attr('id', 'tooltipExtraPadding')
      .attr('class', styles.extraPadding);

    groupMain
      .on('mouseleave', function () {
        tooltip.style('display', 'none');
        d3.select(container).select('#selectedTick').style('display', 'none');
        d3.select(container).selectAll(currentStageActive).attr('r', DEFAULT_POINT_SIZE);
      })
      .on('mousemove', function (event) {
        let x0 = scaleLinearX.invert((d3).pointer(event)[0]);
        x0 = Math.round(x0);

        const isXVisible = (x0 >= scaleLinearX.domain()[0]) && (x0 <= scaleLinearX.domain()[1]);
        if (!isXVisible) {
          return;
        }

        const index = bisect(allPredictions, x0, 1);

        const previousPoint = allPredictions[index - 1];
        const currentPoint = allPredictions[index];
        let selectedPoint: PredictionPoint;

        if (currentPoint) {
          selectedPoint = x0 - previousPoint.x > currentPoint.x - x0 ? currentPoint : previousPoint;
        } else {
          selectedPoint = previousPoint;
        }

        if (!selectedPoint) {
          return;
        }

        if (!(selectedPoint.x < firstStage || selectedPoint.x > lastStage)) {
          tooltip.style('display', 'block');
          d3.select(container).select('#selectedTick').style('display', 'block');
        }

        const dataByStage = allPredictions.find(item => item.x === selectedPoint?.x) as PredictionPoint;
        if (!dataByStage) {
          return;
        }

        const pointsList: PredictionPoint[] = allPredictions.filter(item => item.x === dataByStage.x) ;
        d3.select(container).selectAll('.points circle').attr('r', DEFAULT_POINT_SIZE);

        if (pointsList?.length === 0) {
          tooltip.style('display', 'none');
          d3.select(container).select('#selectedTick').style('display', 'none');
          return;
        }

        const higherValue = getHigherValue({ chartParameter, packerId, pointsList });
        const lowestValue = getLowestValue({ chartParameter, packerId, pointsList });

        const marginLeft = scaleLinearX(higherValue.x);
        const marginBottom = getMarginBottom({ chartParameter, packerId, lowestValue, scaleLinearY });
        
        currentStageActive = `.stage${higherValue.x}`;
        d3.select(container).selectAll(currentStageActive).attr('r', POINT_ACTIVE_SIZE);

        const tooltipDialogWidth = 160;
        const bubbleWidth = 17; // this needs to be the same as defined in the css
        const tooltipTotalWidthDefault = tooltipDialogWidth + bubbleWidth;

        if (marginLeft + tooltipTotalWidthDefault < width) {
          tooltip.classed(styles.rightAlignedTooltip, false);
          tooltip.classed(styles.leftAlignedTooltip, true);
        } else {
          tooltip.classed(styles.rightAlignedTooltip, true);
          tooltip.classed(styles.leftAlignedTooltip, false);
        }
        const leftPositionProps = { marginLeft, tooltipDialogWidth, bubbleWidth, width };

        tooltipExtraPadding
          .style('width', '16px') // has to be the same that value of left
          .style('left', calculateTooltipExtraPaddingLeft({ marginLeft, width, tooltip }));

        tooltip
          .style('left', getChartLeftPosition(leftPositionProps))
          .style('bottom', calculateTooltipBottom({ marginBottom, height, tooltip }));

        d3.select(container).select('#selectedTick')
          .attr('x1', marginLeft)
          .attr('x2', marginLeft);

        tooltipContent.selectAll('*').remove();
        renderTooltipsContent({ pointsList, tooltipContent, chartParameter, currencySymbol, packerId, getCommercialSize });
      });
  }

  /* eslint-disable max-depth*/
  renderTooltipsContent = (props: { pointsList: PredictionPoint[]; tooltipContent: d3.Selection<HTMLDivElement, unknown, null, undefined>; chartParameter: string; currencySymbol: string; packerId?: string; getCommercialSize: (props: { point?: PredictionPoint; packerId?: string; }) => CommercialSizeData | undefined; }) => {
    const { pointsList, tooltipContent, chartParameter, currencySymbol, packerId, getCommercialSize } = props;
    
    for (let index = 0; index < pointsList.length; index++) {
      const point = pointsList[index];

      const entry = tooltipContent
        .append('div')
        .attr('class', styles.entry);

      const entryContent = entry.append('div')
        .attr('class', styles.entryContent);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('shadedplot.day')}: <strong>${formatter(stockingPhaseTypes.ADULT, point.x)}</strong>`);

      if (chartParameter === chartParameters.WEIGHT) {
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('analysis.resultData.averageWeight')}: <strong>${roundTwoDecimals(point.y)} g</strong>`);

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('analysis.resultData.uniformity')}: <strong>${roundTwoDecimals(point.uniformity)} %</strong>`);

        point?.survival && entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('stockings.populations.survival')}: <strong>${roundTwoDecimals(point.survival)} %</strong>`);

        point?.population && entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('stockings.populations.population')}: <strong>${applyThousandsSeparator(point.population)} anim</strong>`);
      }

      if (chartParameter === chartParameters.BIOMASS_KG || chartParameter === chartParameters.BIOMASS_LB) {
        const biomass = applyThousandsSeparator(Math.round(point?.biomass || 0));
        const biomassLb = applyThousandsSeparator(Math.round(point?.biomassLb || 0));

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('stockings.populations.biomass')} (kg): <strong>${biomass}</strong>`);
        
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('stockings.populations.biomass')} (lb): <strong>${biomassLb}</strong>`);
      }

      if (chartParameter === chartParameters.POTENTIAL_INCOME || chartParameter === chartParameters.TOTAL_ACCUMULATED_COST) {
        const commercialSize = getCommercialSize({ point: point, packerId });
        
        if (!commercialSize?.potentialIncome) {
          break;
        }

        const potentialIncome = applyThousandsSeparator(roundOneDecimal(commercialSize?.potentialIncome || 0));
        const potentialGain = applyThousandsSeparator(roundOneDecimal(commercialSize?.potentialGain || 0));
        const potentialGainByDayHectarea = applyThousandsSeparator(roundOneDecimal(commercialSize?.potentialGainByDayHectarea || 0));
        const totalAccumulatedCost = applyThousandsSeparator(roundOneDecimal(point?.totalAccumulatedCost || 0));

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('optimalHarvestPoint.potentialIncome')}: <strong>${currencySymbol}${potentialIncome}</strong>`);

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('optimalHarvestPoint.potentialGain')}: <strong>${currencySymbol}${potentialGain}</strong>`);

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('optimalHarvestPoint.potentialGainByDayHectarea')}: <strong>${currencySymbol}${potentialGainByDayHectarea}</strong>`);

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('optimalHarvestPoint.totalAccumulatedCost')}: <strong>${currencySymbol}${totalAccumulatedCost}</strong>`);
      }

      if (chartParameter === chartParameters.CORRECTED_FOOD) {
        const correctedFoodQuantity = applyThousandsSeparator(roundOneDecimal(point?.correctedFoodQuantity || 0));
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('optimalHarvestPoint.foodQuantity')}: <strong>${correctedFoodQuantity}</strong>`);
      }

      const dateLabel = i18next.t('analysis.predictionDate');
      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${dateLabel}: <strong>${formatLongDateWithZone({ date: point.predictionDate })}</strong>`);


      if (index !== pointsList.length - 1) {
        tooltipContent.append('hr');
      }
    }
  }
}
