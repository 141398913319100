import i18next from 'i18next';

import { weightUnitsByCompany } from '../config/commons';
import { ProcessedCommercialSizeRange } from '../pages/Company/Packers/interfaces';

interface PriceTableDisableProps {
  date: string;
  paymentDelayDays: number | string | null | undefined;
  processedDataSourceWhole: ProcessedCommercialSizeRange[];
  processedDataSourceTail: ProcessedCommercialSizeRange[];
  yieldPercentageWhole: number | string | null | undefined;
  yieldPercentageTail: number | string | null | undefined;
  yieldPercentageWaste: number | string | null | undefined;
  wastePrice: number | string | null | undefined;
}

export const isValidCommercialSizePriceTable = (props: {processedDataSource: ProcessedCommercialSizeRange[]}) => {
  const { processedDataSource } = props;
  return processedDataSource.every(commercialSize => !!commercialSize.price);
};

export const getPrices = (props: {processedDataSource: ProcessedCommercialSizeRange[]}): number[] => {
  const { processedDataSource } = props;
  return processedDataSource.map(commercialSize => commercialSize.price) as number[];
};

export const assignPricesToProcessedData = (props: {processedDataSource: ProcessedCommercialSizeRange[]; prices: number[] | undefined}): ProcessedCommercialSizeRange[] => {
  const { processedDataSource, prices } = props;

  if (!prices?.length) {
    return processedDataSource;
  }

  return processedDataSource.map((item, index) => ({
    ...item,
    price: prices[index],
  }));
};

export const disablePriceTableForm = (props: PriceTableDisableProps): boolean => {
  const { date, paymentDelayDays, processedDataSourceTail, processedDataSourceWhole, yieldPercentageTail, yieldPercentageWhole, yieldPercentageWaste, wastePrice } = props;

  if (!yieldPercentageWhole && !yieldPercentageTail) {
    return true;
  }

  const isWholeValid = !yieldPercentageWhole || isValidCommercialSizePriceTable({ processedDataSource: processedDataSourceWhole });
  const isTailValid = !yieldPercentageTail || isValidCommercialSizePriceTable({ processedDataSource: processedDataSourceTail });

  const missingRequiredFields = !date || !paymentDelayDays || !isWholeValid || !isTailValid;
  return yieldPercentageWaste ? missingRequiredFields || !wastePrice : missingRequiredFields;
};

export const calcYieldPorcentageTail = (props: { whole?: number | string; tail?: number | string; }) => {
  const { whole, tail } = props;

  if (!whole && !tail) {
    return 0;
  }

  if (!whole) {
    return Number(tail);
  }


  const remainingPortion = 100 - Number(whole);
  return (remainingPortion * (Number(tail) / 100));
};

export const calcYieldPorcentageWaste = (props: { whole?: number | string; tail?: number | string; }) => {
  const { whole, tail } = props;

  if (!whole && !tail) {
    return 100;
  }

  if (!tail) {
    return 100 - Number(whole);
  }

  if (!whole) {
    return 100 - Number(tail);
  }

  const remainingPortion = 100 - Number(whole);
  return remainingPortion - (remainingPortion * (Number(tail) / 100));
};

export const getWeightUnit = (props: { weightUnit: string; }) => {
  const { weightUnit } = props;

  switch (weightUnit) {
    case weightUnitsByCompany.KILOGRAM:
      return i18next.t('weightUnit.kilogram');
    

    case weightUnitsByCompany.POUND:
      return i18next.t('weightUnit.pound');
    
  
    default:
      return '';
  }
};
