import * as d3 from 'd3';

import { DEFAULT_STAGE_MAX } from '../../../../config/commons';
import { chartParameters } from '../../../../pages/OptimalHarvestPoint/helpers';
import { CommercialSizeData, DataSource, IPoint, PredictionPoint, Point, PocByPacker } from '../../../../pages/OptimalHarvestPoint/interfaces';

export function calcStages (params: { dataSource: DataSource; maxStage: number; isExcluding: boolean }) {
  const { dataSource, maxStage = DEFAULT_STAGE_MAX, isExcluding } = params;

  const allAnalysisStages = dataSource.allAnalysis.map(item => item.inputData.stage);
  const allPredictionStages = dataSource.predictions.map(item => item.inputData.stage);

  if (!isExcluding) {
    const firstStage = Math.max(...allAnalysisStages);
    let lastStage = Math.max(...allPredictionStages);
    lastStage = lastStage > maxStage ? maxStage : lastStage;
    return [firstStage, lastStage];
  }
  
  const allStages = [...allAnalysisStages, ...allPredictionStages];
  const firstStage = Math.min(...allStages);
  let lastStage = Math.max(...allStages);
  lastStage = lastStage > maxStage ? maxStage : lastStage;

  return [firstStage, lastStage];
}

export function calcYValues (params: { points: IPoint[]; chartParameter: string }) {
  const { points, chartParameter } = params;
  let allValues: number[] = [];
  let allPoints: Point[] = [];
  let predictionPoints: PredictionPoint[] = [];
  let commercialSizeDataPoints: CommercialSizeData[] = [];
  
  switch (chartParameter) {
    case chartParameters.WEIGHT:
    default:
      allPoints = points.filter(item => 'y' in item) as Point[];
      allValues = allPoints.map(item => item.y);
      break;
    case chartParameters.BIOMASS_KG:
      predictionPoints = points.filter(item => 'biomass' in item) as PredictionPoint[];
      allValues = predictionPoints.map(item => item.biomass);
      break;
    case chartParameters.BIOMASS_LB:
      predictionPoints = points.filter(item => 'biomassLb' in item) as PredictionPoint[];
      allValues = predictionPoints.map(item => item.biomassLb);
      break;
    case chartParameters.CORRECTED_FOOD:
      predictionPoints = points.filter(item => 'correctedFoodQuantity' in item) as PredictionPoint[];
      allValues = predictionPoints.map(item => item.correctedFoodQuantity);
      break;
    case chartParameters.POC:
      commercialSizeDataPoints = points.filter(item => 'potentialGainByDayHectarea' in item) as CommercialSizeData[];
      allValues = commercialSizeDataPoints.filter(item => item.potentialGainByDayHectarea).map(item => item.potentialGainByDayHectarea);
      break;
    case chartParameters.POTENTIAL_INCOME:
      commercialSizeDataPoints = points.filter(item => 'potentialIncome' in item) as CommercialSizeData[];
      allValues = commercialSizeDataPoints.filter(item => item.potentialIncome).map(item => item.potentialIncome);
      break;
    case chartParameters.TOTAL_ACCUMULATED_COST:
      predictionPoints = points.filter(item => 'totalAccumulatedCost' in item) as PredictionPoint[];
      allValues = predictionPoints.map(item => item.totalAccumulatedCost);
      break;
  }

  const minY = Math.min(...allValues);
  const maxY = Math.max(...allValues);

  return [minY, maxY];
}

export const renderTickFormat = (domainValue: d3.AxisDomain) => {
  return Number.isInteger(domainValue) && Number(domainValue) >= 0 ? domainValue.toString() : '';
};

interface LeftPositionProps {
  marginLeft: number;
  tooltipDialogWidth: number;
  bubbleWidth: number;
  width: number;
}

export const getChartLeftPosition = (props: LeftPositionProps) => {
  const { marginLeft, tooltipDialogWidth, bubbleWidth, width } = props;

  const tooltipTotalWidth = tooltipDialogWidth + bubbleWidth;

  let value = 0;
  if (marginLeft + tooltipTotalWidth < width) {
    value = marginLeft + (tooltipDialogWidth / 2.5) + (bubbleWidth / 2);
  } else {
    value = marginLeft - (tooltipDialogWidth / 1.5) - (bubbleWidth * 2);
  }

  return `${value}px`;
};

export const getPointColor = (index: number) => {
  switch (index) {
    case 0:
      return '#43A047';
  
    case 1:
      return '#1E88E5';

    case 2:
      return '#FB8C00';

    default:
      return '#BDBDBD';
  }
};

export const getCommercialSizeDataPoints = (props: { allPredictions: PredictionPoint[]; bestPackers: PocByPacker[]; }) => {
  const { allPredictions, bestPackers } = props;

  if (allPredictions.length === 0) {
    return [];
  }

  let commercialSizeDataPoints: CommercialSizeData[] = [];
  
  for (const prediction of allPredictions) {
    const { commercialSizeData } = prediction;
    const data = commercialSizeData.filter(item => bestPackers.some(packerInfo => packerInfo.packerId === item.packerId));
    commercialSizeDataPoints = [...commercialSizeDataPoints, ...data];
  }

  return commercialSizeDataPoints;
};

export const getYValue = (props: {point: IPoint; chartParameter: string; }) => {
  const { chartParameter, point } = props;

  switch (chartParameter) {
    default:
    case chartParameters.WEIGHT:
      return 'y' in point ? point.y : 0;
    case chartParameters.CORRECTED_FOOD:
      return 'correctedFoodQuantity' in point ? point.correctedFoodQuantity : 0;
    case chartParameters.BIOMASS_KG:
      return 'biomass' in point ? point.biomass : 0;
    case chartParameters.BIOMASS_LB:
      return 'biomassLb' in point ? point.biomassLb : 0;
    case chartParameters.POTENTIAL_INCOME:
      return 'potentialIncome' in point ? point.potentialIncome : 0;
    case chartParameters.POC:
      return 'potentialGainByDayHectarea' in point ? point.potentialGainByDayHectarea : 0;
    case chartParameters.TOTAL_ACCUMULATED_COST:
      return 'totalAccumulatedCost' in point ? point.totalAccumulatedCost : 0;
  }
};

export const getCommercialSize = (props: {point?: PredictionPoint; packerId?: string; }) => {
  const { packerId, point } = props;
  const currentCommercialSize = point ? point?.commercialSizeData.find((item) => item.packerId === packerId) : undefined;
  return currentCommercialSize;
};

export const getCommercialSizeData = (props: { allPredictions: PredictionPoint[]; packerId?: string; }) => {
  const { allPredictions, packerId } = props;
  
  const commercialSizeData: CommercialSizeData[] = [];

  for (let index = 0; index < allPredictions.length; index++) {
    const point = allPredictions[index];
    const commercialSize = getCommercialSize({ point, packerId });

    if (!commercialSize) {
      continue;
    }
    
    commercialSizeData.push(commercialSize);
  }

  return commercialSizeData;
};

export const getYDomainData = (props: { allPredictions: PredictionPoint[]; chartParameter: string; packerId?: string; bestPackers?: PocByPacker[] }) => {
  const { allPredictions, chartParameter, packerId, bestPackers } = props;
  const marginPercentage = 0.015;
  const minMargin = 0.5;

  let minY = 0;
  let maxY = 0;

  switch (chartParameter) {
    case chartParameters.POTENTIAL_INCOME: {
      const points: CommercialSizeData[] = getCommercialSizeData({ allPredictions, packerId });
      const [minYValue, maxYValue] = calcYValues({ points, chartParameter });
      minY = minYValue;
      maxY = maxYValue;
      break;
    }

    case chartParameters.POC: {
      if (!bestPackers || !bestPackers.length) {
        break;
      }
      const points = getCommercialSizeDataPoints({ allPredictions, bestPackers });
      const [minYValue, maxYValue] = calcYValues({ points, chartParameter });
      minY = minYValue;
      maxY = maxYValue;
      break;
    }
  
    default: {
      const [minYValue, maxYValue] = calcYValues({ points: allPredictions, chartParameter });
      minY = minYValue;
      maxY = maxYValue;
      break;
    }
  }

  if (maxY < 10) {
    const range = maxY - minY;
    minY = minY - (range * marginPercentage);
    maxY = maxY + (range * marginPercentage);

    return {
      maxY,
      minY,
    };
  }

  const range = maxY - minY;
  const margin = Math.max(range * marginPercentage, minMargin);

  minY -= margin;
  maxY += margin;

  return {
    maxY,
    minY,
  };
};

const getYPosition = (props: {point: PredictionPoint | CommercialSizeData; chartParameter: string; scaleLinearY: d3.ScaleLinear<number, number, never>; packerId?: string;}) => {
  const { chartParameter, point, scaleLinearY, packerId } = props;

  if (chartParameter === chartParameters.POC) {
    const potentialGainByDayHectarea = (point as CommercialSizeData).potentialGainByDayHectarea;
    if (potentialGainByDayHectarea === undefined) {
      return 0;
    }

    return scaleLinearY(potentialGainByDayHectarea);
  }

  const predictionPoint = (point as PredictionPoint);

  switch (chartParameter) {
    default:
    case chartParameters.WEIGHT: {
      return scaleLinearY(predictionPoint.y);
    }
    
    case chartParameters.CORRECTED_FOOD: {
      return scaleLinearY(predictionPoint.correctedFoodQuantity);
    }

    case chartParameters.BIOMASS_KG: {
      return scaleLinearY(predictionPoint.biomass);
    }
      
    case chartParameters.BIOMASS_LB: {
      return scaleLinearY(predictionPoint.biomassLb);
    }
      
    case chartParameters.POTENTIAL_INCOME: {
      const commercialSize = getCommercialSize({ point: predictionPoint, packerId });
      return scaleLinearY(commercialSize?.potentialIncome || 0);
    }

    case chartParameters.TOTAL_ACCUMULATED_COST: {
      return scaleLinearY(predictionPoint.totalAccumulatedCost);
    }
  }
};

export const generateLines = (props: {chartParameter: string; scaleLinearX: d3.ScaleLinear<number, number, never>; scaleLinearY: d3.ScaleLinear<number, number, never>; packerId?: string; }) => {
  const { chartParameter, scaleLinearX , scaleLinearY, packerId } = props;
  
  const lineCurve = d3.line<PredictionPoint | CommercialSizeData>()
    .x((point) => { return scaleLinearX(point?.x); })
    .y((point) => getYPosition({ point, chartParameter, scaleLinearY, packerId }))
    .curve(d3.curveBasis);

  return { lineCurve };
};

export const getHigherValue = (props: { chartParameter: string; pointsList: PredictionPoint[]; packerId?: string; }) => {
  const { chartParameter, pointsList, packerId } = props;

  return pointsList.reduce(function (prev: PredictionPoint, current: PredictionPoint) {
    switch (chartParameter) {
      default:
      case chartParameters.WEIGHT:
        return 'y' in prev && 'y' in current && (prev.y > current.y) ? prev : current;
      case chartParameters.BIOMASS_KG:
        return 'biomass' in prev && 'biomass' in current && (prev.biomass > current.biomass) ? prev : current;
      case chartParameters.BIOMASS_LB:
        return 'biomassLb' in prev && 'biomassLb' in current && (prev.biomassLb > current.biomassLb) ? prev : current;
      case chartParameters.CORRECTED_FOOD:
        return 'correctedFoodQuantity' in prev && 'correctedFoodQuantity' in current && (prev.correctedFoodQuantity > current?.correctedFoodQuantity) ? prev : current;
      case chartParameters.POTENTIAL_INCOME: {
        const previousCommercialSize = getCommercialSize({ point: prev, packerId });
        const currentCommercialSize = getCommercialSize({ point: current, packerId });
        return previousCommercialSize && currentCommercialSize && (previousCommercialSize.potentialIncome > currentCommercialSize.potentialIncome) ? prev : current;
      }
      case chartParameters.TOTAL_ACCUMULATED_COST:
        return 'totalAccumulatedCost' in prev && 'totalAccumulatedCost' in current && (prev.totalAccumulatedCost > current.totalAccumulatedCost) ? prev : current;
    }
  });
};

export const getLowestValue = (props: { chartParameter: string; pointsList: PredictionPoint[]; packerId?: string; }) => {
  const { chartParameter, pointsList, packerId } = props;

  return pointsList.reduce(function (prev: PredictionPoint, current: PredictionPoint) {
    switch (chartParameter) {
      default:
      case chartParameters.WEIGHT:
        return 'y' in prev && 'y' in current && (prev.y < current.y) ? prev : current;
      case chartParameters.BIOMASS_KG:
        return 'biomass' in prev && 'biomass' in current && (prev.biomass < current.biomass) ? prev : current;
      case chartParameters.BIOMASS_LB:
        return 'biomassLb' in prev && 'biomassLb' in current && (prev.biomassLb < current.biomassLb) ? prev : current;
      case chartParameters.CORRECTED_FOOD:
        return 'correctedFoodQuantity' in prev && 'correctedFoodQuantity' in current && (prev.correctedFoodQuantity < current.correctedFoodQuantity) ? prev : current;
      case chartParameters.POTENTIAL_INCOME: {
        const previousCommercialSize = getCommercialSize({ point: prev, packerId });
        const currentCommercialSize = getCommercialSize({ point: current, packerId });
        return previousCommercialSize && currentCommercialSize && (previousCommercialSize.potentialIncome < currentCommercialSize.potentialIncome) ? prev : current;
      }
      case chartParameters.TOTAL_ACCUMULATED_COST:
        return 'totalAccumulatedCost' in prev && 'totalAccumulatedCost' in current && (prev.totalAccumulatedCost < current?.totalAccumulatedCost) ? prev : current;
    }
  });
};

export const getMarginBottom = (props: { chartParameter: string; scaleLinearY: d3.ScaleLinear<number, number, never>; packerId?: string; lowestValue: PredictionPoint }) => {
  const { chartParameter, scaleLinearY, packerId, lowestValue } = props;

  switch (chartParameter) {
    case chartParameters.WEIGHT:
    default:
      return scaleLinearY(lowestValue.y);

    case chartParameters.BIOMASS_KG:
      return scaleLinearY(lowestValue.biomass);
      
    case chartParameters.BIOMASS_LB:
      return scaleLinearY(lowestValue.biomassLb);
      
    case chartParameters.CORRECTED_FOOD:
      return scaleLinearY(lowestValue.correctedFoodQuantity);
      
    case chartParameters.POTENTIAL_INCOME: {
      const commercialSize = getCommercialSize({ point: lowestValue, packerId });

      if (!commercialSize?.potentialIncome) {
        return 0;
      }

      return scaleLinearY(commercialSize.potentialIncome);
    }

    case chartParameters.TOTAL_ACCUMULATED_COST:
      return scaleLinearY((lowestValue as PredictionPoint).totalAccumulatedCost);
  }
};

export const calculateTooltipExtraPaddingLeft = (props: {marginLeft: number; width: number; tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> }) => {
  const { marginLeft, width, tooltip } = props;

  let value = 0;
  const tooltipTotalWidth = tooltip.node()?.offsetWidth || 0;

  if ((marginLeft + tooltipTotalWidth) < width) {
    value = -16; // has to be the same that width, but negative
  } else {
    value = tooltipTotalWidth;
  }

  return `${value}px`;
};

export const calculateTooltipBottom = (props: {marginBottom: number; height: number; tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> }) => {
  const { marginBottom, height, tooltip } = props;

  let value = 0;
  const tooltipTotalHeight = tooltip.node()?.offsetHeight || 0;
  if (marginBottom + tooltipTotalHeight > height) {
    value = (height - marginBottom) + (tooltipTotalHeight / 4);
  } else {
    value = (height - marginBottom) - (tooltipTotalHeight / 4);
  }

  return `${value}px`;
};